.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.container{
  background-color: #e8edff;
}
.no-padding{
  padding: 0;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader{
  width: 100vw;
    height: 100vh;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(224, 224, 224, 0.3);
    z-index: 1450;
}
.dashboard-header{
  font-size: 24px;
  margin:0 5px;
}
.primary{
  color:#3f51b5;
}
.danger{
  color:rgba(220, 53, 69, 1);
}
.warning{
  color:#FF7518;
}
.wrapper {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #1a75c9;
  padding-left: 10px;
}

.line {
  border-top: 1px solid grey;
  flex-grow: 1;
  margin: 2.5px 20px 0 10px;
}
.datepickerLabel {
    position: relative;
    bottom: -10px;
    font-size: 14px;
    font-weight: 500;
    display:block;
}
.summaryTotal{
    float: right;
    padding: 10px 0;
    font-weight: 700;
    font-size: 15px;
}
.highlightedInput{
  background-color: #eee;
}
.highlightedInput:hover{
  border-color: #eee;
}
.disabled{
  background: #eee;
}
.subheaderText{
  padding: 10px;
    font-size: 15px !important;
    font-weight: 500 !important;
    background-color: #1a75c9;
    color:"#fff";
}
.multiSelect{
  width:81%;
  position:relative;
  top:11px;
}
.multiSelectInput{
  border: 1px solid #ccc;
    height: auto;
    border-radius: 5px;
}

.multiSelectInputRequired{
    height: 38px;
    border-radius: 5px;
}
.multiSelectInput:hover{
  border: 1px solid #3f51b5;
}
.multiSelectInput:active{
  border: 2px solid #3f51b5;
}
.headerImage{
  width:90vw;
}

.globalNav{
  /* position:relative; */
  bottom:10px;
  width:100%;
  text-align: right;
}
.footer{
  padding: 15px 0 10px 20px;
}
.footerText{
  font-size: 14px !important;
  text-align: center;
  text-transform: capitalize !important;
}
.decoratedfooterText{
  cursor:pointer;
  font-size: 14px !important;
  text-align: center;
  text-transform: capitalize !important;
  text-decoration: 2px underline #c0effa;
}
.decoratedText{
  cursor:pointer;
  font-size: 14px !important;
  text-align: left;
  text-transform: capitalize !important;
  text-decoration: 2px underline #121414;
  padding-left: 10px !important;
  padding-top: 10px !important;

}
.dashboardWelcome{
  float: left;
    position: relative;
    top: 25px;
    font-size: 24px;
    font-weight: 500;
    color: #131937;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}
.globalNavButton{
  width: 100%;
    padding-top: 0;
    /* margin-right: 25px; */
    text-align: center;
}
.norowsText{
  width: 100%;
    margin-left: 35%;
    font-weight: 600;
    font-size: inherit;
}
::-webkit-scrollbar{
  display:none;
}

.forgotPasswordText{
  display: block;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    font-size: 15px !important;
}

.hideElement{
  display: none !important;
}

@media only screen and (max-width: 1200px) {
   .alignWelcomedrop{
     float:right;
   }
}
