body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.MuiOutlinedInput-input{
  padding: 0;
}
.MuiFormLabel-root.Mui-error{
  color: rgba(0,0,0,1) !important;
}
.MuiFormLabel-root.Mui-focused{
  color: #3f51b5 !important;
}
.MuiFormLabel-root{
  color: rgba(0,0,0,1) !important;
}
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline{
  border-color: rgba(0,0,0,0.23) !important;
  border-left: 5px solid red !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #3f51b5 !important;
}
.required .MuiOutlinedInput-notchedOutline{
  border-left:5px solid green !important;
}
.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
  border-color: #3f51b5 !important;
}
.MuiLinearProgress-root{
  margin: 0 50px !important;
  border-radius: 10px !important;
  height:13px !important;
}
.MuiLinearProgress-bar{
  border-radius:10px !important;
  background-color: #ff9100 !important;
}
.MuiInputBase-input.Mui-disabled{
  color:#000 !important;
}
.input-away-label{
  font-size: 13px !important;
  position: absolute;
  top: -7px;
}
.MuiSnackbar-root{
  max-width: 300px !important;
}

.MuiAppBar-colorPrimary{
  background-color: #041d45 !important;
}
.MuiOutlinedInput-input{
  font-size:15px !important;
}
.MuiFormControlLabel-labelPlacementStart{
  margin-left: 1px !important;
}
.MuiInput-underline:before{
  content:none !important;
}
.MuiInput-underline:after{
  content:none !important;
}
.MuiButton-containedPrimary,.MuiFab-primary, .MuiChip-colorPrimary{
  background-color: #1a75c9 !important;
}
.MuiButton-containedPrimary:hover,.MuiFab-primary:hover, .MuiChip-colorPrimary:hover{
  background-color: #145b9c !important;
}
.MuiRadio-colorPrimary.Mui-checked, .MuiCheckbox-colorPrimary.Mui-checked, .MuiButton-outlinedPrimary{
  color:#1a75c9 !important;
}
.MuiButton-outlinedPrimary{
  border: 1px solid rgba(26, 117, 201,1) !important;
}
.MuiButton-outlinedPrimary:hover{
  border: 1px solid rgba(26, 117, 201, 1) !important;
}
.MuiButton-contained.Mui-disabled{
  background-color: rgba(0, 0, 0, 0.3) !important;
}
.MuiDialog-paperWidthSm{
  max-width: 1000px !important;
}